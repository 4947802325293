import { detectIncognito } from "detectincognitojs";

function isUnsupportedBrowsers() {
  return (
    /Instagram/.test(navigator.userAgent) ||
    /UCBrowser/.test(navigator.userAgent) ||
    /OPR/.test(navigator.userAgent)
  );
}

export const useBrowserDetect = () => {
  const { isChrome, isAndroid } = useDevice();
  const { isTwaSession } = useDetectWebApp();
  const isIncognito = ref(false);
  const isSupportedBrowser = ref(true);

  onMounted(async () => {
    isIncognito.value = (await detectIncognito()).isPrivate;
    isSupportedBrowser.value =
      (isChrome || isAndroid || isTwaSession.value) &&
      !isIncognito.value &&
      !isUnsupportedBrowsers();
  });

  return { isChrome, isTwaSession, isSupportedBrowser, isIncognito };
};
