export const useDetectWebApp = (twaQueryKey: string = "twa") => {
  const route = useRoute();
  const isPwaSession = ref(false);
  const hasTwaQuery = ref(false);

  function isInStandaloneMode() {
    return Boolean(
      (window.matchMedia &&
        window.matchMedia("(display-mode: standalone)").matches) ||
        window.navigator.standalone, // Fallback for iOS
    );
  }

  const isTwaSession = computed(() => {
    return hasTwaQuery.value && isPwaSession.value;
  });

  onMounted(() => {
    if (isInStandaloneMode()) {
      isPwaSession.value = true;
      sessionStorage.setItem("is_pwa_session", "yes");
    } else {
      isPwaSession.value = sessionStorage.getItem("is_pwa_session") === "yes";
    }

    if (route.path === "/" && route.query[twaQueryKey] !== undefined) {
      hasTwaQuery.value = true;
      sessionStorage.setItem("is_twa_session", "yes");
    } else {
      hasTwaQuery.value = sessionStorage.getItem("is_twa_session") === "yes";
    }
  });

  return { isPwaSession, isTwaSession };
};
