<template>
  <section class="py-40 sm:py-48 lg:py-56 min-h-app bg-black">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="max-w-2xl mx-auto text-center">
        <h1
          class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl"
        >
          Welcome!
        </h1>
        <p
          v-if="isLoading || isPageLoading"
          class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white"
        >
          Please wait...
        </p>
        <p
          v-else
          class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white"
        >
          Login to your account
        </p>
      </div>

      <div class="relative max-w-md mx-auto mt-8 md:mt-16">
        <div class="overflow-hidden rounded-md px-4 py-6 sm:px-8 sm:py-7">
          <AppLoader v-if="isLoading" />
          <template v-else>
            <NuxtLink
              v-if="installedRelatedAppUrl && !isPwaSession"
              :to="installedRelatedAppUrl"
              external
            >
              <button class="gsi-material-button max-w-sm" tabindex="-1">
                <div class="gsi-material-button-state"></div>
                <div class="gsi-material-button-content-wrapper">
                  <span class="gsi-material-button-contents"
                    >Open GymBlaze App</span
                  >
                </div>
              </button>
            </NuxtLink>
            <button
              v-else-if="route.query.anon"
              class="gsi-material-button max-w-sm"
              @click="signIn()"
            >
              <div class="gsi-material-button-state"></div>
              <div class="gsi-material-button-content-wrapper">
                <span class="gsi-material-button-contents"
                  >Sign In Anonymously</span
                >
              </div>
            </button>
            <button
              v-else
              class="gsi-material-button max-w-sm"
              @click="signIn()"
            >
              <div class="gsi-material-button-state"></div>
              <div class="gsi-material-button-content-wrapper">
                <div class="gsi-material-button-icon">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="display: block"
                  >
                    <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    ></path>
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    ></path>
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    ></path>
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    ></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                </div>
                <span class="gsi-material-button-contents"
                  >Sign in with Google</span
                >
              </div>
            </button>
            <p v-if="error" class="text-red-500">{{ error }}</p>
            <p class="text-white mt-4">
              By signing in, you agree to the
              <AppLink to="/terms">Terms of Service</AppLink> and
              <AppLink to="/privacy">Privacy Policy</AppLink>.
            </p>
          </template>
        </div>
      </div>
    </div>
    <template v-if="!isSupportedBrowser && showBrowserPopup">
      <div
        class="fixed top-0 left-0 w-screen h-screen bg-black opacity-60"
      ></div>
      <div
        v-if="isChrome && !isIncognito"
        class="fixed top-20 md:top-24 right-6 text-white z-10 flex"
      >
        <p class="mt-6 mr-2 text-2xl font-medium">
          Click <b class="font-black text-gold-500">Open in Chrome</b><br />on
          the top right menu
        </p>
        <div>
          <Icon name="fa6-solid:arrow-up-long" class="rotate-45" />
        </div>
      </div>
      <div
        class="fixed bottom-0 md:bottom-1/2 md:translate-y-1/2 left-1/2 transform -translate-x-1/2 w-screen md:max-w-xl h-auto bg-black p-6 border border-gray-300 shadow-[0_0_20px_#555]"
      >
        <p class="text-base md:text-lg mb-4">
          For the best experience, we recommend opening GymBlaze in Google
          Chrome and please ensure that you are not using incognito mode.
        </p>
        <NuxtLink
          to="https://www.google.com/chrome"
          rel="noopener noreferrer"
          external
          target="_blank"
        >
          <AppButton
            once
            tabindex="-1"
            variant="ternary"
            ghost
            class="w-full mb-3"
            >Download Google Chrome</AppButton
          >
        </NuxtLink>
        <AppButton
          variant="ternary"
          ghost
          class="w-full mb-3"
          @click="showBrowserPopup = false"
          >Stay Here</AppButton
        >
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
import {
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
  signInWithPopup,
  signInAnonymously,
} from "firebase/auth";
import { useFirebaseAuth } from "vuefire";

definePageMeta({
  middleware: ["auth"],
});

const { isLoading: isPageLoading } = useLoadingIndicator();

const { isSupportedBrowser, isChrome, isIncognito } = useBrowserDetect();
const showBrowserPopup = ref(true);

const googleAuthProvider = new GoogleAuthProvider();

const auth = useFirebaseAuth()!; // only exists on client side

const isLoading = ref(true);

const error = ref<string | undefined>();

const route = useRoute();

async function signIn() {
  isLoading.value = true;
  try {
    if (route.query.anon === "1") {
      await signInAnonymously(auth).then(() => {
        window.location.reload();
      });
    } else if (useRuntimeConfig().public.env === "dev") {
      await signInWithPopup(auth, googleAuthProvider).then(() => {
        window.location.reload();
      });
    } else {
      await signInWithRedirect(auth, googleAuthProvider);
    }
  } catch (reason) {
    error.value = (reason as Error)?.message ?? reason ?? "Failed to sign in";
    isLoading.value = false;
  }
}

const { isPwaSession } = useDetectWebApp();
const installedRelatedAppUrl = ref<string>();

// only on client side
onMounted(async () => {
  isLoading.value = true;
  if ("getInstalledRelatedApps" in window.navigator) {
    const relatedApps = await window.navigator.getInstalledRelatedApps();
    if (relatedApps.length > 0 && relatedApps[0].platform === "play") {
      installedRelatedAppUrl.value = relatedApps[0].url;
    }
  }
  getRedirectResult(auth)
    .then((result) => {
      if (!result) isLoading.value = false;
    })
    .catch((reason) => {
      isLoading.value = false;
      console.error("Failed redirect result", reason);
      error.value = reason;
    });
});
</script>

<style scoped>
/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable declaration-block-no-redundant-longhand-properties */

@import "https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap";

.gsi-material-button {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: white;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: Roboto, arial, sans-serif;
  font-size: 18px;
  height: 56px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 22px;
  margin-right: 12px;
  min-width: 22px;
  width: 22px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: Roboto, arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 0.38;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 0.38;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 0.12;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow:
    0 1px 2px 0 rgb(60 64 67 / 30%),
    0 1px 3px 1px rgb(60 64 67 / 15%);
  box-shadow:
    0 1px 2px 0 rgb(60 64 67 / 30%),
    0 1px 3px 1px rgb(60 64 67 / 15%);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 0.08;
}
</style>
